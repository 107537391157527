<template>
  <nuevo-turno color="#f0f0f2" />
</template>
<style>
/* Event Card List CSS */
.event-card-list {
	margin-top: 4em;
}

.event-card-list li {
	list-style: none;
	margin: 2em 0;
}

.event-card {
	overflow: hidden;
	width: 630px;

	border-radius: 0.3em;
}

.event-card img {
	width: 240px;
	height: 200px;

	object-fit: cover;
}

.event-card .name {
	font-size: 2.3em;
	font-weight: 400;
}

.event-card .name a {
	text-decoration: none;
	/*color: #212121;*/
}

.event-card .date {
	font-size: 1.4em;
	font-weight: 400;
	color: #6D6D6D;
}

.event-card .location {
	font-size: 1em;
	color: #757575;
}

.event-card .location i {
	font-size: 1.1em;
	padding-right: 0.3em;
	margin-bottom: 0.085em;
}

.event-card .desc {
	margin-bottom: 0.2em;
	font-size: 1.16em;
	padding-left: 0.1em;
}

.event-card .date-ribbon {
	position: absolute;
	top: 0;
	left: 2em;
	background: #FE453E;
	color: #fff;
	padding: 0.2em 1em;
	padding-bottom: 0;
	border-radius: 0;
}

.event-card .date-ribbon::before, .event-card .date-ribbon::after {
	content: '';
	position: absolute;
	top: 100%;
	
	width: 50%;
	height: 30px;
}

.event-card .date-ribbon::before {
	left: 0;
	border-left:solid 2em #FE453E;
	border-top: solid 15px #FE453E;
	border-bottom: solid 15px transparent;
	border-right: solid 2em transparent;
	}

.event-card .date-ribbon::after {
	right: 0;
	border-right:solid 2em #FE453E;
	border-top: solid 15px #FE453E;
	border-bottom: solid 15px transparent;
	border-left: solid 2em transparent;
}

.event-card .date-ribbon h2 {
	font-weight: 500;
	font-size: 1.15em;
	letter-spacing: 0.07em;
	text-align: center;
}

.event-card .date-ribbon h1 {
	text-align: center;
	font-weight: 400;
	font-size: 2.45em;
	margin-top: -0.09em;
	line-height: 1em;
}
/* Below is page css. Not event card */

.wrapme {
	width: 70%;
	max-width: 940px;
}

.search-bar {
	margin-top: 1em;
	background: #fff;
	padding: 1em;
	width: 100%;
	border-radius: 10em;
	
	transition: box-shadow 300ms ease;
}

.search-bar input {
	width: 100%;
	border-style: none;
		
	color: inherit;
	background-color: transparent;

	padding-left: 1em;
	font-size: 1.3em;
}

.search-bar input:focus {
	outline: none;
}

.upcoming-events-filter-group {
	padding: 0 2.4em;

	position: relative;
	display: inline-block;
}

.upcoming-events-filter-group input{
	visibility: hidden;
	opacity: 0;
	position: absolute;
	top: -999;
	left: -999;
}

.upcoming-events-filter-group label {
	cursor: pointer;
	font-size: 1.3em;
	margin: 0 0.3em;

	color: #9E9E9E;

	transition: color 300ms ease;
}

.upcoming-events-filter-group input:checked + label {
	color: #F07077;
}

.upcoming-events-filter-group .underline {
	position: absolute;
	bottom: -3px;
	left: 2.73em;

	height: 2px;
	width: 6em;
	background: #F07077;

	transition: 300ms ease;
}

.upcoming-events-filter-group #importantSelect:checked ~ .underline {
	left: 2.73em;
	width: 6.2em;
}

.upcoming-events-filter-group #upcomingSelect:checked ~ .underline {
	left: 9.45em;
	width: 6em;
}

.upcoming-events-filter-group #finishedSelect:checked ~ .underline {
	left: calc(100% - 2.7em - 5em);
	width: 5em;
}
.v-card--link:before {
   background: none!important;
    }



.radio-btn-group {
  display: -webkit-box;
  display: flex;
}
.radio-btn-group .radio {
  margin: 0 1.5rem;
}
.radio-btn-group .radio label {
  background: #fff;
  border: 1px solid #ddd;
  padding: .5rem 1.25rem;
  border-radius: 28px;
  cursor: pointer;
  color: #444;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  -webkit-transition: box-shadow 400ms ease;
  transition: box-shadow 400ms ease;
}

   
.radio-btn-group .radio label:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.radio-btn-group .radio input[type="radio"] {
  display: none;
}
.radio-btn-group .radio input[type="radio"]:checked + label {
  background: #3c8dbc !important;
  color: #fff;
  border-color: #3c8dbc !important;
}


.active{
	background-color: #6D6D6D!important;
}

.v-stepper__content {
    top: 0;
    padding: 14px 14px 8px!important;
    flex: 1 0 auto;
    width: 100%;
}
.v-main{
	min-height: 450px;
}
.v-application .primary {
    background-color: #3c8dbc !important;
    border-color: #3c8dbc !important;
}
.v-application{
	font-family: "Nunito";
}
.v-picker{
	border-radius: 24px!important;
}
@media (max-width: 760px) {
.layout .upcoming-events-filter-group{
	padding:0;

}
.upcoming-events-filter-group label{
	font-size: 16px;

}

.upcoming-events-filter-group #importantSelect:checked ~ .underline {
    left: 0.73em;
    width: 6.2em;
}
.layout .search-bar{

    padding: 0.7em;
}

.search-bar input {

    padding-left: 0.7em;
    font-size: 1em;
}
.conteiner{
	padding: 7px;
}
}
.v-btn__content {
    font-weight: bold!important;

}
.v-date-picker-table .v-btn {
    font-size: 15px!important;
}
.titulos {
    font-size: 1.45rem!important;

}
.subtitulos {
    font-size: .990rem!important;
	}
</style>
<script>
  import NuevoTurno from '../components/NuevoTurno'

  export default {
    name: 'Home',

    components: {
      NuevoTurno,
    },
  }
</script>
