<style scoped>
.v-application{
	font-family: "Nunito";
}


</style>
<template>
  <v-app id="top" >
    <v-app-bar
 
      color="#CAD1E3"
      dark
    
      scroll-target="#scrolling-techniques"

    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2 rounded-circle"
          contain
          src="https://open.openpeluqueros.com.ar/img_entornos/open9.jpg"
          transition="scale-transition"
          
          width="40"
        />
Open Estudio - Reservá tu turno
 
      </div>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
