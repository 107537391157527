<template>

  <v-stepper v-model="e1" >
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Sucursal y Servicios
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
       Datos Turno
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step 
       :complete="e1 > 3"
      step="3" >
       Datos Personales
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>

        <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
      <v-stepper-content step="1">
    <v-row class="text-center">

      <v-col   md="12"
        >

<v-main style="background: #e9ecef" class="rounded-t-xl">

		<v-container>
   <v-layout justify-center>
                  <v-layout class="wrapme" column align-center justify-center>
          <v-row align="center" style="width:100%">


            <v-col        cols="12"
                  sm="12" class="mt-2">
                  
         <v-card-title class="titulos">Sucursales</v-card-title>
         <v-card-subtitle class="text-left subtitulos">Primero, seleccione la sucursal en donde quiera sacar el turno</v-card-subtitle>
            </v-col>
          </v-row>
                  </v-layout>
               </v-layout>
    <v-slide-group
      v-model="model"
      class="pa-4"
      
            align="center"
            justify="center"
    >
    <v-col align-self="center" v-for="(n,index) in sucursales" :key="n.id">
   <v-slide-item 
      class="rounded-xl"
    
        :key="index"
        v-slot="{ active, toggle }"

      >
        <v-card
          :color="active ? '#3c8dbc' : 'white lighten-1'"
          class="ma-4"
          height="130"
          width="110"
          @click="toggle"
        >
          <v-row

            class="fill-height rounded-xl"
            align="center"
            justify="center"
            color="transparent"

          >
          <v-col cols="12">                 <v-img
          src="https://open.openpeluqueros.com.ar/img_entornos/open9.jpg"
               class="shrink rounded-circle"
          contain
       width="50"
                /></v-col>
                  <v-col cols="12">   <p :class="active ? 'white--text' : 'black--text'">{{ n.title }}</p>
          </v-col>
            
     
          </v-row>
        </v-card>
      </v-slide-item>
    </v-col>
   
    </v-slide-group>
    </v-container>
		<v-container>

   <v-layout justify-center  v-show="servicios.length > 0">
      <v-layout class="wrapme" column align-center justify-center>
          <v-row align="center" style="width:100%">


            <v-col        cols="12"
                  sm="12" class="mt-2">
                  
         <v-card-title class="titulos">Servicios </v-card-title>
         <v-card-subtitle class="text-left subtitulos">Ahora, seleccione los servicios que desea realizarse</v-card-subtitle>
            </v-col>
          </v-row>
                  </v-layout>
               </v-layout>
           
			<v-layout justify-center v-show="servicios.length > 0"> 
				<v-layout class="wrapme" column align-center justify-center>
					<div :class="['search-bar', searchIsFocused ? 'elevation-6' : 'elevation-3']">
						<input placeholder="Busca un servicio" 
								v-on:focus="searchFocus()"
								v-on:blur="searchUnfocus()"
								type="text"
								name="search"
								v-model="filter.search">
					</div>
					<v-layout align-center justify-space-between row style="width: 100%; margin-top:10px;">
						<div class="upcoming-events-filter-group">
							<!-- <input type="radio" id="importantSelect" name="important-select" value="important" v-model="eventsUpcomingFilter" @change="upcomingFilterChange()">
							<label for="importantSelect">Más solicitados</label> -->
                 
							<!-- <input type="radio" id="upcomingSelect" name="upcoming-select" value="upcoming" v-model="eventsUpcomingFilter" @change="upcomingFilterChange()">
							<label for="upcomingSelect">Promos</label>
							<input type="radio" id="finishedSelect" name="finished-select" value="finished" v-model="eventsUpcomingFilter" @change="upcomingFilterChange()">
							<label for="finishedSelect">Finished</label> -->
							<!-- <div class="underline"></div> -->
              <v-chip
              v-if="filter.categories != ''"
                  close
                  color="green"
                  outlined
                    @click:close="filter.categories = ''"
              
                >
                  {{filter.categories}}
                </v-chip>
    

						</div>

					    <v-menu
              transition="slide-y-transition"
              bottom>
                 <template v-slot:activator="{ on, attrs }">
                   	<v-btn  style="align-self: flex-end; color: #9E9E9E; margin-right: 1.4em"    outlined
                    rounded
                    small
                     v-bind="attrs"
                      v-on="on"
                    >
                    <span style="padding-right: 0.4em;" >Categorías</span>
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>
                   
                  </template>

                  <v-list>
                    <v-list-item
                      v-for="(item, i) in categorias"
                      :key="i"
                    >
                      <v-list-item-title @click="filtrar(item)" style="cursor:pointer;">{{ item.nombre }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
					</v-layout>

<br>
      <v-row dense style="width:100%">
       <v-col
          v-for="(item, i) in filteredServices"
          :key="i"
            cols="12"
        sm="4"
        >

   <v-card
    :loading="loading"
    class="mx-auto my-6 rounded-xl "
    max-width="374"
    
    
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img v-if="item.img != ''"
    class="rounded-xl"

      height="120"
      style="margin:8px; top:5px!important;  "
      position="top center"
    
      :src="rutaImagenes+'/img_serv/'+item.id+'/'+item.img"
    ></v-img>
    <v-img v-if="item.img === ''"
    class="rounded-xl"

      height="120"
      style="margin:8px; top:5px!important;  "
      position="top center"
    
      src="https://open.openpeluqueros.com.ar/img_entornos/open9_default.jpg"
    ></v-img>
    <v-layout>

    <v-card-title class="pt-1"  v-text="item.nombre"></v-card-title>
           <v-spacer></v-spacer>   <v-card-subtitle class="pt-3"  v-text="item.tiempo_t+' min.'"> </v-card-subtitle> 
    </v-layout>
  
    <v-divider class="mx-1"></v-divider>
    <v-card-actions    >
      <v-list-item class="grow">
           <v-list-item-content>
        <div class=" text-subtitle-1">
        $ {{ item.precio }}
      </div>
           </v-list-item-content>
    
         <v-row 
          align="center"
          justify="end"
          style="margin:0px!important"
        >
          <v-btn v-if="form.servicios.indexOf(item)>-1"
           class="ml-2  "
                    outlined
                    rounded
                    small
                    right
                  @click="agregarServicio(item)"
      >
        Agregado
      </v-btn>
           <v-btn v-else
           class="ml-2 "
        
                    outlined
                    rounded
                    small
                       right
                  @click="agregarServicio(item)"
      >
        Agregar
      </v-btn>
        </v-row>
      </v-list-item>
    </v-card-actions>
        <v-overlay
          :absolute="absolute"
          :value="form.servicios.indexOf(item)>-1"
        >
            <v-btn v-if="form.servicios.indexOf(item)>-1"
           class="ml-2 mt-2"
                    outlined
                    rounded
                    small
                       right
                            color="white"
                  @click="agregarServicio(item)"
      >
        Eliminar
      </v-btn>
        </v-overlay>
  </v-card>
        </v-col>
      </v-row>
      
      
   	<v-layout align-center justify-space-between row style="width: 100%; ">
       <v-card style="width: 100%; "  class=" mx-auto my-6 rounded-xl " >
         	<div class="upcoming-events-filter-group" style="float: left;">
  <v-card-title>Sucursal:  {{form.sucursal.title}}</v-card-title>

         

                 </div>

                 <div style="align-self: flex-end; color: #9E9E9E; margin-right: 1.4em; float:right"  >
                            <v-card-text v-for="(servs,i) in form.servicios " :key="i" style="padding:5px;">{{servs.nombre}} - ${{servs.precio}}</v-card-text>
                            <v-card-title>TOTAL: $ {{sumServices}}</v-card-title>
			</div>
       </v-card>
         		

		
            </v-layout>
                </v-layout>
              </v-layout>
            </v-container>
          </v-main>

              </v-col>


            </v-row>
        <v-card-actions   style="float:right;"> 

            

                <v-btn
                :disabled="form.servicios.length == 0 "
                  color="primary"
                  @click="paso2()"
                    rounded
                    right
                >
                  Siguiente
                </v-btn>
                  </v-card-actions>
              </v-stepper-content>

              <v-stepper-content step="2">
                <v-main style="background: #e9ecef" class="rounded-xl">
            <v-container>
                                    
               <v-layout justify-center>
                 <v-layout class="wrapme" column align-center justify-center>
                  <v-row align="center" style="width:100%">


                    <v-col        cols="12"
                          sm="12" class="mt-5">
                          
                <v-card-title class="titulos">Profesional a cargo</v-card-title>
                <v-card-subtitle class="subtitulos">Puede seleccionar un profesional o bien cualquier profesional disponible</v-card-subtitle>
                          <v-container class="pt-5" >
                          <v-select
                          v-model="form.profesional"
                            :items="profesionales"
                            label="Seleccione profesional a cargo"
                                hint="Profesional a cargo"
                            item-text="nombre"
                            item-value="id"
                            dense
                            @change="getDisponibilidades"
                          >
                          </v-select>
                          </v-container>
                          </v-col>
                      <v-spacer></v-spacer>
                    <v-col        cols="12"
                          sm="12">
                          <v-container class="pt-5" v-show="arrayDates.length > 0">
                            <v-date-picker
                            rounded
                        v-model="form.date"
                        full-width
                        class="mt-4"
                          :allowed-dates="allowedDates"
                          color="#3c8dbc"
                          header-color="#3c8dbc"
                            locale="es-AR"
                      ></v-date-picker>

                          </v-container>
                    
                          </v-col>

                    <v-col  cols="12"   sm="12">
                              <v-container class="pt-5" v-show="horas.length > 0">

                <v-card-title class="titulos">Horarios</v-card-title>
                <v-card-subtitle class="subtitulos">Estos son los horarios disponibles para el día seleccionado</v-card-subtitle>
                              
                                  <div class="radio-btn-group" >
                                    <v-row class="pt-2">
                                    
                                  <v-col  cols="12" md="6">
                                    <v-card-subtitle style="color:#3c8dbc">MAÑANA</v-card-subtitle> 

                                    <v-row>
                                      <v-col v-show="filteredManiana.length === 0"  cols="12" md="12" >
                                          <small class="pl-3" >
                                            Ups. No hay horarios para la mañana
                                          </small> 
                                        </v-col>
                                          
                                         <v-col  cols="6" md="4" v-for="(horario, key) in filteredManiana" :key="key">

                                                          
                                                            <div class="radio "  >
                                                          <input class="" type="radio" name="radio" :value="horario.horas"  :id="horario.horas "  v-model="form.horario_checked"  @click="changeProf(horario.prof)" /> <label :for="horario.horas" >{{ horario.horas }}</label>
                                                          </div>
                                                    
                                                        
                                                      


                                        </v-col>
                                    </v-row>
                            

                                  </v-col>
                                  <v-col  cols="12" md="6">
                                    <v-card-subtitle style="color:#3c8dbc">TARDE</v-card-subtitle> 
                                    <v-row>  
                                      <v-col v-show="filteredTarde.length === 0"  cols="12" md="12" >
                                      
                                        <p class="pl-3">
                                        Ups. No hay horarios para la tarde
                                        </p> 
                                      </v-col>
                                        <v-col  cols="6" md="4" v-for="(horario, key) in filteredTarde" :key="key">
                                      
                                                          
                                                  <div class="radio "  >
                                                      <input class="" type="radio" name="radio" :value="horario.horas"  :id="horario.horas "  v-model="form.horario_checked"  @click="changeProf(horario.prof)"  /> <label :for="horario.horas" >{{ horario.horas }}</label>
                                                      </div>
                                                
                                        </v-col>

                                    </v-row>
                          
                                  </v-col>  
                                  </v-row>
                                        </div>   

              </v-container>
                                           
            </v-col>
                                  
            </v-row>
                                              
                      
                
            </v-layout >
            </v-layout >
            </v-container>
          </v-main >
          <v-card-actions   style="float:right;"> 

                  <v-btn text rounded   @click="e1 = 1">
                    Atras
                  </v-btn>
                  <v-btn
                   :disabled="form.horario_checked == '' "
                    rounded

                    color="primary"
                    @click="paso3()"

                
                  >
                    Siguiente
                  </v-btn>
                  </v-card-actions>

       
 </v-stepper-content>

      <v-stepper-content step="3">



  <v-main style="background: #e9ecef" class="rounded-xl">
              <v-container>
      			<v-layout justify-center v-show="!estadoReserva">
                  <v-layout class="wrapme" column align-center justify-center>
          <v-row align="center" style="width:100%">


            <v-col        cols="12"
                  sm="12" class="mt-5">
                  <v-container>
                           <v-card-title class="titulos">Datos Personales</v-card-title>
         <v-card-subtitle class="subtitulos">Para completar la reserva necesitamos algunos datos personales</v-card-subtitle>
        <v-subheader>* Si ya sos cliente completá tu numero de teléfono y verificá si te encontras en nuestra base de datos</v-subheader>
                     <v-form v-model="validCli">
    <v-container>
      
      <v-row>
          <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="form.cliente.telefono"
            :rules="telRules"
            label="Telefono"
            hint="Ej:2215435544 sin el 0 y sin el 15"
               placeholder="Ej:2215435544 sin el 0 y sin el 15"
            required
          ></v-text-field>
        </v-col>
                       <v-col
          cols="12"
          md="6"
        >
      <v-btn
      :disabled="form.cliente.telefono === ''"
      class="ma-2"
      outlined
      color="#3c8dbc"
      @click="verificarCliente()"
    >
     Verificar Cliente
    </v-btn>
     <v-alert
     v-show="okCliente"
     dismissible
  outlined
  prominent
      text
      type="success"
    >
     Perfecto! Ya sos parte de Open!
    </v-alert>
   
    <v-alert
    v-show="errorCliente"
  color="red"
  dismissible
  outlined
  prominent
  text
  type="error"
> No hay coincidencias, completá tus datos!</v-alert>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >

          <v-text-field
            v-model="form.cliente.nombre"
            :rules="nameRules"
            :counter="10"
            label="Nombre"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="form.cliente.apellido"
            :rules="apeRules"
            :counter="10"
            label="Apellido"
            required
          ></v-text-field>
        </v-col>
            <v-col
          cols="12"
          md="12"
        >
          <v-text-field
            v-model="form.cliente.email"
          :rules="emailRules"
            label="Email "
            placeholder="Dejanos tu Email para poder enviarte el turno"
            
          ></v-text-field>
        </v-col>
                <v-col
          cols="12"
          md="12"
        >
          <v-textarea
          rows="5"
            v-model="form.comentarios"
       
            label="Comentarios "
            placeholder="Si lo precisas, dejanos un comentario..."
            
          ></v-textarea>
        </v-col>
  
          </v-row>
        </v-container>
      </v-form>
                  
                  </v-container>
 
          
    </v-col>

                                  
            </v-row>
            </v-layout >
                    </v-layout >


      		<v-layout justify-center v-if="estadoReserva">
              <v-layout class="wrapme" column align-center justify-center>
                  <v-row align="center" style="width:100%">

                    <v-col cols="12"  sm="12" class="mt-5">


                       <v-card
    class="mx-auto my-12"
    max-width="420"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title class="text-center">Hola {{ form.cliente.nombre }}, Turno confirmado!</v-card-title>
         <v-list-item>
      <v-list-item-icon>
        <v-icon color="#3c8dbc">mdi-store</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{form.sucursal.title}}</v-list-item-subtitle>
    </v-list-item>
     <v-list-item>
      <v-list-item-icon>
        <v-icon color="#3c8dbc">mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{moment(form.date).format('L')}}</v-list-item-subtitle>
    </v-list-item>
    <v-list-item>
      <v-list-item-icon>
        <v-icon color="#3c8dbc">mdi-clock</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{form.horario_checked}}</v-list-item-subtitle>
    </v-list-item>

          <v-card-subtitle class="pb-0">Servicios</v-card-subtitle>
        <v-list-item v-for="(servicio,i) in form.servicios" :key="i">
      <v-list-item-icon>
        <v-icon color="#3c8dbc">mdi-content-cut</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{servicio.nombre}}</v-list-item-subtitle>
    </v-list-item>
  <v-list-item>
      <v-list-item-icon>
        <v-icon color="#3c8dbc">mdi-currency-usd</v-icon>
      </v-list-item-icon>
      <v-list-item-subtitle>{{sumServices}}</v-list-item-subtitle>
    </v-list-item>
    
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
   
      <div>Te enviamos un correo con el recordatorio del turno, muchas gracias! Te esperamos!</div>
    </v-card-text>

  </v-card>

                      

                    </v-col>
                  </v-row>
              </v-layout>
          </v-layout>



            </v-container>
          </v-main >

        <v-card-actions   style="float:right;" v-show="!estadoReserva" > 
            <v-btn text rounded>
              Cancelar
            </v-btn>
    
            <v-btn
                          rounded
                    color="primary"
                    @click="reservarTurno()"
                  class=" rounded-xl"
                  :disabled="!validCli"

            >
              Confirmar Turno
            </v-btn>

                  </v-card-actions>

      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<style scoped>

.v-btn__content {
    font-weight: bold;

}
.v-date-picker-table .v-btn {
    font-size: 15px!important;
}
</style>
<script>
import axios from 'axios'
  export default {
    data () {
      return {
          host: location.host,
          model: null,
         absolute: true,
         arrayDates:[],
       overlay:false,
       estadoReserva:false,
             
        loading: false,
        rutaImagenes:"https://openv2.openpeluqueros.com.ar",
        filterUpcoming:"",
        filterImportant:"",
        filterSearch:"",
        searchIsFocused: false,
        eventsUpcomingFilter: 'important',
        okCliente:false,
        errorCliente:false,
        filter: {
          categories: "",
          importants: true,
          search: ''
        },
        form:{
          date:"",
        sucursal:'',
        profesional:'',
        servicios:[],
        ref:this.$route.query.ref,
        horario_checked:"",
        total:0,
        comentarios:"",
        pqp:'',
        cliente:{
          id:null,
          nombre:"",
          apellido:"",
          telefono:"",
          email:""
        }
        },  nameRules: [
        v => !!v || 'El nombre es requerido',
      ],
        apeRules: [
        v => !!v || 'El apellido es requerido',
      ],
        telRules: [
        v => !!v || 'El telefono es requerido',
      ],emailRules: [
         v => /.+@.+\..+/.test(v) || 'El E-mail debe ser válido, será necesario para el envío del turno',
      ],
      validCli:true,
              sucursales: [
         { id:'suc9', title: '9 E/ 50 y 51', src: 'https://open.openpeluqueros.com.ar/img_entornos/open9.jpg', flex: 6 },
          ],

        profesionales: [],
            horarios: [],
             horas: [],
            e1: 1,
              servicios:[],
              categorias:[],
         goToOptions: {
      duration: 10000,
      offset: 0,
      easing: 'easeInOutCubic',
    },
          }
    },
    watch:{

         'form.date': function(val, oldVal){
           if(val != ""){
              this.horas=this.horarios[val]
           }else{
               this.horas=[]
           }

          
       
         },  'model': function(val, oldVal){

           if(val >= 0){
              this.form.sucursal=this.sucursales[val];
              this.changeSucursal(this.form.sucursal.id)
           }else{
          
           }
         }
    },
    methods:{
  
  allowedDates(val) {
      for (var i = 0; i < this.arrayDates.length; i++) {
         if (this.arrayDates[i] == val){
            return val
         } 
      }
   },filtrar(item){
     this.filter.categories = item.nombre
   },
      reserve () {
        this.loading = true

        setTimeout(() => (this.loading = false), 2000)
      },
      		searchFocus() {
			this.searchIsFocused = true;
		},
		searchUnfocus() {
			this.searchIsFocused = false;
		},
    agregarServicio(item){
              let pos = this.form.servicios.indexOf(item)
            pos === -1 ? this.form.servicios.push(item) : this.form.servicios.splice(pos,1)
      // this.form.servicios.push(item);

    },verificarCliente(){
this.overlay = true
          var  params={
              sucursal:this.form.sucursal.id,
              // fecha:this.date,
              cliente:this.form.cliente.telefono,
            }
            var config={
              headers: {"Access-Control-Allow-Origin": "*"}
            }
             var url=this.getRuta()+"verificarCliente.php";
        axios
          .get(url,{params})
          .then(response => {
            // console.log(response.data)
            if (response.data === 0) {
              //no se encuentra en la bse de datos
              this.errorCliente=true;
        
            }else{
                 this.form.cliente.id=response.data.id;
              this.form.cliente.nombre=response.data.nombre;
              this.form.cliente.apellido=response.data.apellido;
              this.form.cliente.email=response.data.mail;
              this.okCliente = true;

            }
            // this.horarios_disponibles = response.data;
            // this.arrayDates=response.data.almanaque;
            // this.horarios=response.data.dias_disponibles;
                   this.overlay = false
           
          })
          .catch(error => {
            console.log(error)
          })
    },
    getRuta(){
      if (this.host === "localhost:8080") {
        return "http://localhost/open-peluqueros/turno/"
      }else{
        return "https://openv2.openpeluqueros.com.ar/turno/"
        //  return "https://openv2.openpeluqueros.com.ar/turno/"
      }
    },
    
    getDisponibilidades(){
        this.overlay = true
        this.resetData()
          var  params={
              sucursal:this.form.sucursal.id,
              // fecha:this.date,
              servicios:this.form.servicios,
              prof:this.form.profesional
            }
            var config={
              headers: {"Access-Control-Allow-Origin": "*"}
            }
            var url=this.getRuta()+"getTurnosDisponibles.php";
        axios
          .get(url,{params})
          .then(response => {
            // console.log(response.data)
            // this.horarios_disponibles = response.data;
            this.arrayDates=response.data.almanaque;
            this.horarios=response.data.dias_disponibles;
                   this.overlay = false
           
          })
          .catch(error => {
            console.log(error)
          })
          },paso2(){

            if(this.form.servicios.length == 0){
              alert("se debe seleccionar un servicio");
            }else{
              this.e1 = 2     
              this.scrollTop();

            }
            
           
          },paso3(){

            if(this.form.servicios.length == 0){
              alert("se debe seleccionar un servicio");
            }else{
              this.e1 = 3     
              this.scrollTop();

            }
            
           
          },scrollTop () {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
           behavior: 'smooth',
        })
      }, 500)
    },changeSucursal(id){
              var  params={
                  suc:id
                }
                var config={
                  headers: {"Access-Control-Allow-Origin": "*"}
                }
                     var url=this.getRuta()+"getServicios.php";
            axios
              .get(url,{params})
              .then(response => {
                this.servicios = response.data.servicios;
                this.categorias = response.data.categorias;
                this.profesionales = response.data.profesionales;
                var indistinto={
                  id:0,
                  imagen:'',
                  nombre:'Cualquier profesional disponible'
                }
                 this.profesionales.push(indistinto)
                   this.form.servicios =[];
              })
              .catch(error => {
                console.log(error)
              })
    },
        changeProf(id){
          if (this.form.profesional === 0) {
        //va con el profesional del horario
        this.form.profesional =id
          }else{
            this.form.pqp=this.form.profesional;
          }
      },
    reservarTurno(){
      this.overlay = true
 
    
       var form=this.form
         var config={
              headers: {"Access-Control-Allow-Origin": "*"}
            }
              var url=this.getRuta()+"procesarReserva.php";
        axios
          .post(url,{form})
          .then(response => {

            if(response.data > 0){
              this.estadoReserva=true
              // this.e1=4
            }else{
              alert("error");
            }
  
            // console.log(response.data)
            // // this.horarios_disponibles = response.data;
            // this.arrayDates=response.data.almanaque;
            // this.horarios=response.data.dias_disponibles;
           this.overlay = false
          })
          .catch(error => {
            console.log(error)
          })
    },resetData(){
      this.arrayDates=[]
      this.horas=[]
      this.form.date="" 
      this.form.horario_checked=""

    }

    },computed:{
   filteredServices() {
      return this.servicios.filter(item => {
        const filter = this.filter.search.toUpperCase();
         var categoria = this.filter.categories;

            if (categoria === "" ){
            
            var hasNameMatch = item.nombre.toUpperCase().includes(filter);
                
            }else{
             
        var hasNameMatch = item.nombre.toUpperCase().includes(filter) && item.name_cat === categoria;
            }
           
       
        
        
        return hasNameMatch;
      }).slice(0, 25)
    },   filteredHours() {
      return this.horarios.filter(item => {
        const filter = this.form.date;
        const hasNameMatch = item.dia === filter;
        
        
        return hasNameMatch;
      })[0];
    },   filteredManiana() {
      return this.horas.filter(item => {
                const desde = "0800";
          const hasta = "1400";
        var valor=  item.horas.split(':');
        var val=valor[0]+valor[1]
        const hasNameMatch = val >= desde && val <= hasta ;  
        return hasNameMatch;
      });
    },filteredTarde() {
      return this.horas.filter(item => {
        const desde = "1415";
          const hasta = "2100";
        var valor=  item.horas.split(':');
        var val=valor[0]+valor[1]
        const hasNameMatch = val >= desde && val <= hasta ;  
        return hasNameMatch;
      });
    },sumServices(){
           var total=0;
    for (var i = 0; i < this.form.servicios.length; i++) {
      total=parseFloat(total) + parseFloat(this.form.servicios[i].precio);
    }
    this.form.total=parseFloat(total);
    return parseFloat(total)
    }
    },mounted () {
    //  console.log(this.host)
              //  this.model=0;
            //   var  params={
            //       suc:"suc54"
            //     }
            //     var config={
            //       headers: {"Access-Control-Allow-Origin": "*"}
            //     }
            // axios
            //   .get('http://localhost/open-peluqueros/turno/getServicios.php',{params})
            //   .then(response => {
            //     this.servicios = response.data.servicios;
            //     this.categorias = response.data.categorias;
            //     this.profesionales = response.data.profesionales;
                
            //   })
            //   .catch(error => {
            //     console.log(error)
            //   })
          }
  }
</script>